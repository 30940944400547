import React from "react";
import styled from "styled-components";

const ASSETS = {
  spotify: require("./assets/spotify.svg").default,
  applemusic: require("./assets/applemusic.svg").default,
  facebook: require("./assets/facebook.svg").default,
  instagram: require("./assets/instagram.svg").default,
  soundcloud: require("./assets/soundcloud.svg").default,
  twitch: require("./assets/twitch.svg").default,
  twitter: require("./assets/twitter.svg").default,
  youtube: require("./assets/youtube.svg").default,
};

const SocialLink = styled.a.attrs((props) => ({
  href: props.url,
  target: "_blank",
  rel: "noopener noreferrer",
}))`
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  padding: 4px;
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }

  background: url(${(props) => ASSETS[props.type]});
  background-size: cover;
  border-radius: 4px;
  opacity: 0.6;
  transition: 120ms ease;

  &:hover {
    opacity: 1;
  }
`;

export default function SocialBar({ socials }) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {socials.map((social, i) => (
        <SocialLink key={social.type} type={social.type} url={social.url}>
          <span className="sr-only">{social.type}</span>
        </SocialLink>
      ))}
    </div>
  );
}
