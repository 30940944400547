import React from "react";

export default function DataPage({ query, children }) {
  // NOTE: Loading is handled by suspense
  const { data: apiResponse, error } = query;

  if (!apiResponse) {
    return null;
  }

  if (error) {
    return (
      <p style={{ color: "red" }}>
        An error occurred :({" "}
        {process.env.NODE_ENV !== "production" ? String(error) : null}
      </p>
    );
  }

  return children(apiResponse.data);
}
