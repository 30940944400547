import React from "react";
import styled from "styled-components";

const VodItems = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const VodItem = styled.li`
  display: block;
  margin-right: 8px;
  margin-bottom: 8px;
  width: 250px;
  height: 250px;
  cursor: pointer;

  .link {
    position: relative;
    display: block;
    text-decoration: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .thumb {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(${(props) => props.vod.thumb});
    background-size: cover;
    transition: 220ms ease;
    /* transform-origin: 48% 45%; */
    will-change: transform;
  }

  &:hover .thumb {
    transform: scale(1.2);
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -12px;
    left: 0;
    right: 0;
    height: 60px;
    transition: 220ms ease;
    will-change: transform;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    line-height: 1.2;
    padding: 0 8px 8px;
  }

  &:hover .text {
    transform: translateY(-12px);
  }
`;

export default function VodList({ vods }) {
  return (
    <VodItems>
      {vods.map((vod) => (
        <VodItem key={vod.id} vod={vod}>
          <a
            className="link"
            href={`/${vod.userId}/vods/${encodeURIComponent(vod.id)}`}
          >
            <div className="thumb" role="presentation" />
            <span className="text">{vod.name}</span>
          </a>
        </VodItem>
      ))}
    </VodItems>
  );
}
