import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { createGlobalStyle } from "styled-components";
import App from "./App";
import * as analytics from "./common/analytics";

const GlobalStyles = createGlobalStyle`
  html,
  body {
    height: 100%;
    color: white;
    background-color: #104e82;
  }
  main {
    margin-top: 1rem;
  }

  .text-muted {
    color: rgba(255, 255, 255, .6) !important;
  }

  a:hover {
    color: #007bff;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>Hyperplane – Live gaming streams as VR stadium experiences</title>
      <meta
        name="description"
        content="Hyperplane let's you experience the hype of watching games/esports wherever, whenever by transporting you into the game in VR."
      />
      <meta
        property="og:title"
        content="Hyperplane – Live gaming streams as VR stadium experiences"
      />
      <meta
        name="og:description"
        content="Hyperplane let's you experience the hype of watching games/esports wherever, whenever by transporting you into the game in VR."
      />
      <meta
        property="og:image"
        content="https://t3dmedia.s3.amazonaws.com/_notvideos/vod-thumb-default.jpg"
      />
      <meta property="og:site_title" content="Hyperplane" />
    </Helmet>
    <GlobalStyles />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

analytics.init();
