import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import logo from "./logo.png";

export default function Header() {
  return (
    <Navbar
      variant="dark"
      expand="lg"
      fixed="top"
      style={{ backgroundColor: "#182b3b" }}
    >
      <Navbar.Brand as={Link} to="/">
        <img
          alt="Logo"
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{" "}
        Hyperplane
      </Navbar.Brand>
    </Navbar>
  );
}
