import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider as HttpProvider } from "use-http";

import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Main from "./layout/Main";
import Home from "./features/Home";
import Streams from "./features/streams";
import DemoGame from "./features/DemoGame";
import MeleeTournamentLandingPage from "./features/MeleeTournamentLandingPage";
import Signup from "./features/Signup";
import LoadingSplash from "./common/LoadingSplash";

function App() {
  return (
    <HttpProvider
      url={process.env.REACT_APP_API_URL}
      options={{ suspense: true }}
    >
      <Router>
        <Suspense fallback={<LoadingSplash />}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/fighter">
              <RegularPath>
                <DemoGame />
              </RegularPath>
            </Route>
            <Route exact path="/ssbm">
              <RegularPath>
                <MeleeTournamentLandingPage />
              </RegularPath>
            </Route>
            <Route exact path="/signup">
              <RegularPath>
                <Signup />
              </RegularPath>
            </Route>
            <Route path="/:streamer">
              <RegularPath>
                <Streams />
              </RegularPath>
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </HttpProvider>
  );
}

function RegularPath({ children }) {
  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  );
}

export default App;
