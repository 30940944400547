import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useFetch from "use-http";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet";

import Marquee from "./Marquee";
import DataPage from "../../common/DataPage";
import { trackVodView } from "../../common/analytics";

export default function UserVodPage() {
  const { streamer: streamerId, vodId: encodedVodId } = useParams();
  const query = useFetch(`/channels/${streamerId}/vods/${encodedVodId}`, {}, [
    streamerId,
    encodedVodId,
  ]);

  useEffect(() => {
    if (!query || !query.data || !query.data.data) {
      return;
    }

    const vod = query.data.data;
    trackVodView(vod.user.id, vod.id);
  }, [query, streamerId]);

  // Some sort of breadcrumbs?
  return (
    <DataPage query={query}>
      {(vod) => (
        <Container fluid>
          <Helmet>
            <title>{`${vod.userId} – ${vod.name} – Hyperplane`}</title>
            <meta
              name="description"
              content={
                vod.description ||
                `${vod.name} is an immersive mixed reality experience by ${vod.userId}. Check it out in your browser, or in VR on your headset!`
              }
            />
            <meta property="og:title" content={`${vod.userId} – ${vod.name}`} />
            <meta
              property="og:description"
              content={
                vod.description ||
                `${vod.name} is an immersive XR experience by ${vod.userId}. Check it out in your browser, or in VR on your headset!`
              }
            />
            <meta property="og:site_title" content="Hyperplane" />
            <meta property="og:type" content="video.other" />
            {/* Show the prof pic url, if it exists, if the VOD thumb is the default. Otherwise show the thumb. Some old VODs don't have thumbs so we use a fallback URL */}
            <meta
              property="og:image"
              content={
                vod.thumb
                  ? vod.thumb.includes("vod-thumb-default.jpg")
                    ? vod.user.profPicUrl || vod.thumb
                    : vod.thumb
                  : "https://t3dmedia.s3.amazonaws.com/_notvideos/vod-thumb-default.jpg"
              }
            />
          </Helmet>
          <Row>
            <Marquee
              url={vod.uri}
              isJohnnyDemoMarqueeForNow={
                vod.user.id === "whitelights" && vod.id === "Capsule Test"
              }
            />
          </Row>
          <Row style={{ marginBottom: 12 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {vod.user.profPicUrl ? (
                <Link to={`/${vod.user.id}`}>
                  <div
                    style={{
                      marginRight: 8,
                      background: `url(${vod.user.profPicUrl})`,
                      width: 72,
                      height: 72,
                      borderRadius: "50%",
                      backgroundSize: "cover",
                      position: "relative",
                    }}
                  >
                    <img
                      alt="profile"
                      src={vod.user.profPicUrl}
                      width={72}
                      height={72}
                      style={{
                        opacity: 0,
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        borderRadius: "inherit",
                      }}
                    />
                  </div>
                </Link>
              ) : null}
              <div
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                <h1>
                  {vod.name}{" "}
                  <small className="text-muted">
                    by <Link to={`/${vod.user.id}`}>@{vod.user.id}</Link>
                  </small>
                </h1>
                <p className="text-muted">
                  {new Intl.NumberFormat().format(vod.viewCount)}{" "}
                  {vod.viewCount === 1 ? "View" : "Views"} •{" "}
                  {DateTime.fromISO(vod.createdAt).toLocaleString(
                    DateTime.DATE_MED
                  )}
                </p>
              </div>
            </div>
          </Row>
        </Container>
      )}
    </DataPage>
  );
}
