import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from "react-helmet";
import * as analytics from "../../common/analytics";
import discordLogo from "./Discord-Logo-Black.svg";
import emailLogo from "./mail.svg";

export default function Signup() {
  return (
    <>
      <Helmet>
        <title>Publish on Hyperplane!</title>
        <meta
          name="description"
          content="Share your 3D content mixed-reality experiences everywhere, instantaneously!"
        />
        <meta property="og:title" content="Sign up to publish on Hyperplane!" />
        <meta
          property="og:description"
          content="We're giving select 3D content creators early access to our mixed-reality 3D publishing platform!"
        />
        <meta property="og:site_name" content="Hyperplane" />
        <meta
          property="og:image"
          content="https://t3dmedia.s3.amazonaws.com/_notvideos/vod-thumb-default.jpg"
        />
      </Helmet>
      <Container fluid="lg">
        <Row className="mb-5">
          <Col>
            <h1>Publish mixed-reality 3D content quickly and easily</h1>
            <p>
              If you currently develop 3D experiences using Unity, and would
              like to distribute those experiences instantaneously to everyone
              with a VR-enabled device, we want you to publish with us! Contact
              us using one of the methods below and one of the Hyperplane
              creators will walk you through getting set up.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md className="border mr-md-2 mb-2 mb-md-0">
            <a
              className="text-reset d-flex flex-column align-items-center "
              style={{ display: "block" }}
              href="https://discord.gg/fBBDu8pBmH"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => analytics.trackSignupViaDiscord()}
            >
              <h2>Discord</h2>
              <img
                src={discordLogo}
                width="122"
                height="120"
                alt="Discord Logo"
              />
              <p>
                Join our server, and introduce yourself in #general letting us
                know you want to publish. One of the founders will DM you!
              </p>
            </a>
          </Col>
          <Col md className="border">
            <a
              className="text-reset d-flex flex-column align-items-center"
              style={{ display: "block" }}
              href="mailto:founders@true3d.live?subject=I%20want%20to%20publish%20to%20Hyperplane"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => analytics.trackSignupViaEmail()}
            >
              <h2>Email</h2>
              <img src={emailLogo} width="120" height="120" alt="Email Logo" />
              <p>Send us a message and we'll get back to you promptly!</p>
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
}
