import React from "react";
import VodList from "./VodList";

export default function Vods({ vods }) {
  return (
    <section>
      <h2>VODs</h2>
      {vods.length > 0 ? (
        <VodList vods={vods} />
      ) : (
        <p>
          The user does not have any VODs. If you know them, encourage them to
          make some ^_^
        </p>
      )}
    </section>
  );
}
