import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import SmashLogo from "./../images/SmashBall.png";

export default function MeleeTournamentLandingPage() {
  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        <img alt="logo" src={SmashLogo} width={128} height={128} />
      </Row>
      <Row className="justify-content-md-center">
        <h1>Melee VR Tournament Sign Ups</h1>
      </Row>
      <Row className="justify-content-md-center">
        <h2>February, 2021</h2>
      </Row>
      <Row className="justify-content-md-center">
        <a href="https://docs.google.com/forms/d/1fmvrJunc1UOmQnnvPLQo468SNpDj8qW9PJaOzpBkvHE">
          Alpha Testers Sign Up Here
        </a>
      </Row>
      <Row className="justify-content-md-center">
        <p>
          In February 2021, we are hosting the first ever Super Smash Bros Melee
          Tournament in VR!
        </p>
      </Row>
      <Row className="justify-content-md-center">
        <p>
          If you want to compete or just be a part of the audience in VR sign up
          above and join our{" "}
          <a href="https://discord.gg/fBBDu8pBmH">discord!</a>
        </p>
      </Row>
      <Row className="justify-content-md-center">
        <p>We will be sending the first invites in early January.</p>
      </Row>
      <Row className="justify-content-md-center">
        <p>See you soon!</p>
      </Row>
    </Container>
  );
}
