import React, { useRef, useEffect, useCallback } from "react";
import True3DPlayerNative from "@true3dco/player";
import * as analytics from "../common/analytics";
import legacyVodUrls from "../legacy-vod-urls.json";

const MAIN_PADDING = 30;
export default function True3DPlayer({ src, ...playerProps }) {
  const container = useRef(null);
  const computeAspect = useCallback(() => {
    let w =
      Math.min(
        playerProps.width || window.innerWidth,
        window.innerWidth - MAIN_PADDING
      ) + (window.innerWidth <= 768 ? 30 : 0);
    let h = Math.min(
      playerProps.height || window.innerHeight,
      window.innerHeight
    );
    // Compute proper aspect ration
    if (w < h) {
      w = h * (w / h);
    } else {
      h = w * (h / w);
    }
    return { w, h };
  }, [playerProps.width, playerProps.height]);

  const playerRef = useRef(null);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);

    function handleResize() {
      if (!playerRef.current) {
        return;
      }
      const { w, h } = computeAspect();
      playerRef.current.setSize(w, h);
    }
  }, [computeAspect]);

  useEffect(() => {
    const { w, h } = computeAspect();
    const player = new True3DPlayerNative(container.current, src, {
      ...playerProps,
      width: w,
      height: h,
      useLegacyCamera: legacyVodUrls.includes(src),
    });
    player.on("error", (err) => {
      player.pause();
      console.error("An error occurred");
      console.error(err);
    });
    player.on("enter-xr", () => analytics.trackEnterXr());
    player.on("exit-xr", (info) => analytics.trackExitXr(info.timeSpentSecs));

    playerRef.current = player;
    return () => {
      playerRef.current.dispose();
    };
  }, [src, playerProps, computeAspect]);

  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        marginLeft: window.innerWidth <= 768 ? -15 : 0,
        marginRight: window.innerWidth <= 768 ? -15 : 0,
      }}
    >
      <div ref={container}></div>
    </div>
  );
}
