import React from "react";
import Container from "react-bootstrap/Container";

export default function Main({ children }) {
  return (
    <Container fluid as="main" style={{ marginTop: 58 }}>
      {children}
    </Container>
  );
}
