import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useFetch from "use-http";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";

import DataPage from "../../common/DataPage";
import Marquee from "./Marquee";
import Vods from "./Vods";
import SocialBar from "./SocialBar/index";

export default function UserStreamPage() {
  const { streamer: streamerId } = useParams();
  const channelQuery = useFetch(`/channels/${streamerId}`, {}, []);
  useEffect(() => {
    if (!(channelQuery.data && channelQuery.data.data)) {
      return;
    }
    const { arenaLink } = channelQuery.data.data;
    if (arenaLink) {
      window.location = arenaLink;
    }
  }, [channelQuery.data]);
  return (
    <DataPage query={channelQuery}>
      {(streamer) =>
        streamer.arenaLink ? (
          <p>
            Redirecting you to{" "}
            <a href={streamer.arenaLink}>{streamer.arenaLink}</a>
          </p>
        ) : (
          <Container fluid>
            <Helmet>
              <title>{`${streamer.id} – Hyperplane`}</title>
              <meta
                name="description"
                content={`${streamer.id} is publishing on Hyperplane, a platform for immersive mixed reality experiences!`}
              />
              <meta property="og:title" content={`${streamer.id}`} />
              <meta
                property="og:description"
                content={`${streamer.id} is publishing on Hyperplane, a platform for immersive mixed reality experiences!`}
              />
              <meta property="og:site_name" content="Hyperplane" />
              <meta property="og:type" content="profile" />
              <meta property="og:profile_username" content={streamer.id} />
              <meta
                property="og:image"
                content={
                  streamer.profPicUrl ||
                  "https://t3dmedia.s3.amazonaws.com/_notvideos/vod-thumb-default.jpg"
                }
              />
            </Helmet>
            <Row>
              {streamer.marquee ? (
                <Marquee url={streamer.marquee.uri} autoPlay />
              ) : (
                <div
                  style={{
                    boxSizing: "border-box",
                    padding: 80,
                    margin: "0 auto",
                    textAlign: "center",
                    maxWidth: "100%",
                  }}
                >
                  <p className="text-muted">
                    No featured content at this time. Check the VODs!
                  </p>
                </div>
              )}
            </Row>
            <Row>
              <ProfileHeader streamer={streamer} />
            </Row>
            <Row>
              <hr className="border border-gray" style={{ width: "100%" }} />
            </Row>
            <Row>
              <Vods vods={streamer.vods} />
            </Row>
          </Container>
        )
      }
    </DataPage>
  );
}

const ProfileHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

function ProfileHeader({ streamer }) {
  return (
    <ProfileHeaderContainer>
      {streamer.profPicUrl ? (
        <div
          style={{
            marginRight: 8,
            background: `url(${streamer.profPicUrl})`,
            width: 128,
            height: 128,
            borderRadius: "50%",
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          <img
            alt="profile"
            src={streamer.profPicUrl}
            width={128}
            height={128}
            style={{
              opacity: 0,
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              borderRadius: "inherit",
            }}
          />
        </div>
      ) : null}
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <h1>
          @{streamer.id}{" "}
          {streamer.live ? (
            <small>
              <span
                className="small badge badge-danger"
                style={{
                  textTransform: "uppercase",
                  verticalAlign: "text-top",
                }}
              >
                Live
              </span>
            </small>
          ) : null}
        </h1>
        <p className="text-muted">{streamer.description}</p>
        <SocialBar socials={streamer.socials} />
      </div>
    </ProfileHeaderContainer>
  );
}
