import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Footer() {
  return (
    <Container fluid as="footer" style={{ marginTop: 24 }}>
      <Row>
        <Col>
          <p className="small text-muted text-center">
            Copyright {new Date().getFullYear()} True3D Technologies, Inc. All
            rights reserved. Made in LA and NYC.{" "}
            <a
              className="text-reset"
              href="https://app.termly.io/document/terms-of-use-for-website/61d0014a-6cfe-4d00-9757-3d47e5816d44"
            >
              Terms of service
            </a>{" "}
            <a
              className="text-reset"
              href="https://app.termly.io/document/privacy-policy/1472b649-da97-4510-8c97-0ab98a0e1233"
            >
              Privacy policy
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  );
}
