import React from "react";
import styled, { keyframes } from "styled-components";
import logo from "./Logo.svg";

const pulse = keyframes`
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
`;
const LoadingSplashContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    display: block;
    animation: ${pulse} 2s ease infinite !important; // Overrides is-preload styles
  }
`;

export default function LoadingSplash(htmlProps) {
  return (
    <LoadingSplashContainer {...htmlProps}>
      <img src={logo} alt="Hyperplane Logo" />
      <h1>Hyperplane</h1>
    </LoadingSplashContainer>
  );
}
