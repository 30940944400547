import React from "react";
import True3DPlayer from "../../common/True3DPlayer";

export default function Marquee({
  url,
  isJohnnyDemoMarqueeForNow,
  autoPlay = false,
}) {
  return (
    <section style={{ marginBottom: 16 }}>
      <True3DPlayer
        src={url}
        autoPlay={autoPlay}
        width={window.innerWidth}
        height={window.innerHeight - 256}
        useFakeLighting={isJohnnyDemoMarqueeForNow}
      />
    </section>
  );
}
