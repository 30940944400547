import React from "react";
import Container from "react-bootstrap/Container";

export default function DemoGame() {
  return (
    <Container fluid>
      <h1>Demo Game</h1>
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          title="Demo Game"
          className="embed-response-item"
          src={`${process.env.PUBLIC_URL}/demogame0/index.html`}
          allowFullScreen
        />
      </div>
    </Container>
  );
}
