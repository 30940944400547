import mixpanel from "mixpanel-browser";

export const events = {
  PAGE_VIEW: "page_view",
  BOUNCE: "bounce",
  ENTER_XR: "enter_xr",
  EXITED_SCENE: "exited_scene",
  CLICK_SIGNUP_LINK: "click_signup_link",
  CLICK_PUBLISH_CTA: "click_publish_cta",
};

export function init() {
  mixpanel.init("78e03d9b1d04a1d59f68fa242f8577ab");
  mixpanel.track(events.PAGE_VIEW);

  window.addEventListener("unload", () => {
    mixpanel.track(events.BOUNCE);
  });
}

export function trackVodView(streamerId, vodId) {
  fetch(
    `${process.env.REACT_APP_API_URL}/channels/${streamerId}/vods/${vodId}/__pv`
  );
}

export function trackEnterXr() {
  mixpanel.track(events.ENTER_XR);
}

export function trackExitXr(timeSpentInSeconds) {
  mixpanel.track(events.EXITED_SCENE, {
    timespent: timeSpentInSeconds,
  });
}

export function trackClickPublishHeaderCta() {
  mixpanel.track(events.CLICK_PUBLISH_CTA, {
    location: "header",
  });
}

export function trackClickPublishFooterCta() {
  mixpanel.track(events.CLICK_PUBLISH_CTA, {
    location: "footer",
  });
}

export function trackSignupViaDiscord() {
  mixpanel.track(events.CLICK_SIGNUP_LINK, {
    method: "discord",
  });
}

export function trackSignupViaEmail() {
  mixpanel.track(events.CLICK_SIGNUP_LINK, {
    method: "email",
  });
}
