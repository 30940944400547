import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import useFetch from "use-http";
import logo from "../../common/Logo.svg";
import * as analytics from "../../common/analytics";
import LoadingSplash from "../../common/LoadingSplash";
import discordLogo from "./Discord-Logo-White.svg";
import emailLogo from "./mail-white.svg";

export default function HomePage() {
  const [stylesLoaded, setStylesLoaded] = useState(false);
  // LOOOOOOOOL
  useEffect(() => {
    const els = [];
    const vendorStyles = ["/homepage/assets/css/main.css"].map(
      (s) => `${process.env.PUBLIC_URL}${s}`
    );

    const stylesLoadPromises = [];
    for (const styleUrl of vendorStyles) {
      const style = document.createElement("link");
      style.rel = "stylesheet";
      style.href = styleUrl;
      els.push(style);
      stylesLoadPromises.push(
        new Promise((resolve) =>
          style.addEventListener("load", () => resolve())
        )
      );
      document.head.appendChild(style);
    }
    Promise.all(stylesLoadPromises).then(() => setStylesLoaded(true));

    return () => {
      for (const el of els) {
        if (el.parentElement) {
          el.parentElement.removeChild(el);
        }
        if (document.getElementById("homestyle")) {
          document
            .getElementById("homestyle")
            .parentElement.removeChild(document.getElementById("homestyle"));
        }
      }
    };
  }, []);

  const query = useFetch("/streams/live?limit=3", { suspense: false }, []);
  const streamsLoaded = useMemo(() => !query.loading, [query.loading]);
  const streams = useMemo(() => (query.data || {}).data || [], [query.data]);
  useEffect(() => {
    if (query.error) {
      console.warn("Error fetching streams:", query.error);
    }
  }, [query.error]);

  const isReadyForDisplay = useMemo(() => stylesLoaded && streamsLoaded, [
    stylesLoaded,
    streamsLoaded,
  ]);
  useEffect(() => {
    if (!isReadyForDisplay) {
      return;
    }

    const vendorScripts = [
      "/homepage/assets/js/jquery.min.js",
      "/homepage/assets/js/jquery.scrollex.min.js",
      "/homepage/assets/js/jquery.scrolly.min.js",
      "/homepage/assets/js/jquery.scrolly.min.js",
      "/homepage/assets/js/browser.min.js",
      "/homepage/assets/js/breakpoints.min.js",
      "/homepage/assets/js/util.js",
      "/homepage/assets/js/main.js",
    ].map((s) => `${process.env.PUBLIC_URL}${s}`);
    const scriptLoadPromises = [];
    const els = [];
    for (const scriptUrl of vendorScripts) {
      const script = document.createElement("script");
      script.src = scriptUrl;
      script.async = false;
      script.defer = false;
      scriptLoadPromises.push(
        new Promise((resolve) => {
          script.addEventListener("load", () => resolve());
        })
      );
      els.push(script);
      document.body.appendChild(script);
    }

    Promise.all(scriptLoadPromises).then(() => {
      window.__homepageinit__ && window.__homepageinit__();
      document.getElementById("intro").classList.remove("inactive");
    });

    return () => {
      Object.defineProperty(window, "__homepageHasAlreadyLoaded__", {
        value: true,
        configurable: true,
      });
      for (let el of els) {
        if (el.parentElement) {
          el.parentElement.removeChild(el);
        }
      }
    };
  }, [isReadyForDisplay]);

  return (
    <>
      <LoadingSplash hidden={isReadyForDisplay} />
      <div hidden={!isReadyForDisplay}>
        {/* Sidebar */}
        <section id="sidebar">
          <div className="inner">
            <nav>
              <ul>
                <li>
                  <a href="#intro">Welcome</a>
                </li>
                {streams.length > 0 ? (
                  <li>
                    <a href="#live">Live Now</a>
                  </li>
                ) : null}
                <li>
                  <a href="#community">Community</a>
                </li>
                <li>
                  <a href="#streamers">Streamers</a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        {/* Wrapper */}
        <div id="wrapper">
          {/* Intro */}
          <section id="intro" className="wrapper style1 fullscreen fade-up">
            <div className="inner">
              <h1>
                <img
                  alt="logo"
                  src={logo}
                  style={{ verticalAlign: "middle" }}
                  width="96"
                  height="96"
                />{" "}
                Hyperplane
              </h1>
              <p>
                Turn your Super Smash Bros Melee Twitch streams into 3D Virtual
                Arenas
              </p>
              <div id="demovid" style={{ maxWidth: 560 }}>
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "56.25%",
                    marginBottom: 12,
                    height: 0,
                    overflow: "hidden",
                  }}
                >
                  {/* Prevents video from playing back too early */}
                  {isReadyForDisplay ? (
                    <iframe
                      title="Demo of our early alpha"
                      className="embed-responsive-item"
                      id="ytplayer"
                      type="text/html"
                      src="https://www.youtube.com/embed/pgVjbFslEnc?autoplay=1"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    ></iframe>
                  ) : null}
                </div>
              </div>
              <ul className="actions">
                <li>
                  <a
                    id="stream-cta"
                    href="#streamers"
                    className="button scrolly"
                  >
                    Stream on Hyperplane
                  </a>
                </li>
                <li>
                  <Link to="/tk/vods/097aef" className="button scrolly">
                    3D Gameplay Example
                  </Link>
                </li>
              </ul>
            </div>
          </section>

          {streams.length > 0 ? (
            <section id="live" className="wrapper style3 spotlights">
              {streams.map((stream) => {
                // FIXME: (Similar code in HyperplaneTV) our generic stream name is MM/DD/YYYY HH:MM so we check for that
                // and use a placeholder if a custom stream name wasn't entered.
                const streamName = /^\d{2}\/\d{2}/.test(stream.name)
                  ? `Super Smash Bros Melee Arena`
                  : stream.name;

                return (
                  <section key={stream.id}>
                    <a href={stream.arenaLink} className="image">
                      <img
                        src={stream.user.profPicUrl || logo}
                        alt={stream.user.id}
                        data-position="center center"
                      />
                    </a>
                    <div className="content">
                      <div className="inner">
                        <h2>{stream.user.id}</h2>
                        <p>{streamName}</p>
                        <ul className="actions">
                          <li>
                            <a href={stream.arenaLink} className="button">
                              Join
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>
                );
              })}
            </section>
          ) : null}

          <section id="community" className="wrapper style2 fade-up">
            <div className="inner">
              <h2>Connect with us!</h2>
              <p>
                We're passionate about bringing the hype of in-person
                tournaments to the SSBM community during the netplay era. If
                you're part of that community, or just want to hear more about
                what we're doing, we'd love to hear from you! Find us anywhere
                below.
              </p>
              <div className="features">
                <section>
                  <span className="icon solid major brands fa-discord"></span>
                  <h3>Discord</h3>
                  <p>
                    We'd love to have you as a part of our active and growing
                    community!
                  </p>
                  <ul className="actions">
                    <li>
                      <a
                        href="https://discord.gg/fBBDu8pBmH"
                        className="button scrolly"
                        onClick={() => analytics.trackSignupViaDiscord()}
                      >
                        Join our server
                      </a>
                    </li>
                  </ul>
                </section>
                <section>
                  <span className="icon solid major brands fa-twitter"></span>
                  <h3>Twitter</h3>
                  <p>Follow us for the latest updates</p>
                  <ul className="actions">
                    <li>
                      <a
                        href="https://twitter.com/hyperplanelive"
                        className="button scrolly"
                      >
                        Follow @hyperplanelive
                      </a>
                    </li>
                  </ul>
                </section>
                <section>
                  <span className="icon solid major brands fa-reddit"></span>
                  <h3>Reddit</h3>
                  <p>
                    Share your experiences on Hyperplane with the community.
                  </p>
                  <ul className="actions">
                    <li>
                      <a
                        href="https://www.reddit.com/r/HyperplaneLive/"
                        className="button scrolly"
                      >
                        Join r/hyperplane
                      </a>
                    </li>
                  </ul>
                </section>
                <section>
                  <span className="icon solid major fa-envelope"></span>
                  <h3>Email</h3>
                  <p>You can always reach us the good ole-fashioned way</p>
                  <ul className="actions">
                    <li>
                      <a
                        href="mailto:hello@true3d.live"
                        className="button scrolly"
                        onClick={() => analytics.trackSignupViaEmail()}
                      >
                        Email the team
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </section>

          {/* Three */}
          <section id="streamers" className="wrapper style1 fade-up">
            <div className="inner">
              <h2>Streamers</h2>
              <p>
                <strong>
                  Create a virtual arena where people can hang out and watch
                  your Twitch streams in 3D.
                </strong>
                <br></br>
                Works with Unranked, Direct, and Spectating Slippi Broadcasts
              </p>
              <div className="split">
                <a
                  href="https://discord.gg/fBBDu8pBmH"
                  onClick={() => analytics.trackSignupViaDiscord()}
                  style={{ borderBottom: "none" }}
                >
                  <section className="align-center">
                    <h3>Discord</h3>
                    <img
                      src={discordLogo}
                      width="122"
                      height="120"
                      alt="Discord Logo"
                    />
                    <p>
                      Post in the #melee channel that you want to stream and we
                      will set you up!
                    </p>
                  </section>
                </a>
                <a
                  href="mailto:founders@true3d.live?subject=I%20want%20to%20publish%20to%20Hyperplane"
                  style={{ borderBottom: "none" }}
                  onClick={() => analytics.trackSignupViaEmail()}
                >
                  <section className="align-center">
                    <h3>Email</h3>
                    <img
                      src={emailLogo}
                      width="120"
                      height="120"
                      alt="Email Logo"
                    />
                    <p>Email works too</p>
                  </section>
                </a>
              </div>
            </div>
          </section>
        </div>

        {/* Footer */}
        <footer id="footer" className="wrapper style1">
          <div className="inner">
            <ul className="menu">
              <li>&copy; True3D Technologies, Inc. All rights reserved.</li>
              <li>
                Design: <a href="http://html5up.net">HTML5 UP</a>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </>
  );
}
