import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import UserStreamPage from "./UserStreamPage";
import UserVodPage from "./UserVodPage";

export default function Streams() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <UserStreamPage />
      </Route>
      <Route path={`${path}/vods/:vodId`}>
        <UserVodPage />
      </Route>
    </Switch>
  );
}
